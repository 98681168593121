<template>
  <modal-card :title="'User notes'">
    <user-notes :user-id="userId" />
  </modal-card>
</template>

<script>
export default {
  name: "UserNotesModal",
  components: {
    "user-notes": () => import("@shared/user/_userNotes")
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  }
};
</script>
